@import "../../../css/variables.scss";

.message-aligner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.is-success-bar {
    background: #23d160 !important;
}

.is-warning-bar {
    background: #ffdd57 !important;
}

.is-danger-bar {
    background: #f14668 !important;
}

.is-info-bar {
    background: #3298dc !important;
}

