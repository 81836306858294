@import "./variables.scss";

.title.is-3{
    font-size: 2em !important;
}

.title.is-4{
    font-size: 1.5em !important;
}

.title.is-5{
    font-size: 1.25em !important;
}

.title.is-6 {
    font-size: 1em !important;
}

.title {
    color: #363636;
    font-size: 2em;
    font-weight: 600;
    line-height: 1.125;
    padding-bottom: 10px;
}