@import "../../../css/variables.scss";
$pageloader-opacity: 0.1;

.loading-aligner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute !important;
    background: transparent !important;
}

.loading-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.pageloader::after{
    animation: 0 !important;
}
