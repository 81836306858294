@import "./bulma.min.css";
@import "./bulmaOverrides.scss";
@import "node_modules/bulmaswatch/default/bulmaswatch.scss";
@import "./variables.scss";
@import "../../node_modules/react-day-picker/lib/style.css";
@import '../../node_modules/react-sm-select/dist/main.scss';
@import '../../node_modules/bulma-extensions/bulma-checkradio/dist/css/bulma-checkradio.sass';
@import '../../node_modules/bulma-extensions/bulma-pageloader/dist/css/bulma-pageloader.sass';

html {
    overflow-y:auto;
    min-height: 100%;
    height: 100%;
}
body {
    min-height: 100%;
    height: 100%;
}

#root {
    display: flex;
    min-height: 100%;
    height: 100%;
    flex-direction: column;
}

.footer {
    color: #dedede;
    background-color: #323232;
    padding: 1.5rem 1.5rem 1.5rem;
    bottom: 0px;
    position: relative;
}

.navbar-start > .navbar-item.is-active,
.navbar-end > .navbar-item.is-active {
    border-bottom: 2px solid gray;
}

.navbar-start > .navbar-item:hover{
    border-bottom: 2px solid lightgray;
}

.navmenu-svg-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 2.9em;
    height: 2.9em;
    > svg {
        width: 1.5em!important;
        height: 1.5em !important;
      }
  }

.table.no-border, 
.table.no-border td,
.table.no-border tr{
    border-width: 0;
}

.navbar-item,
.navbar-link {
    padding: 0rem 0.75rem;
}

@media (min-width: $tablet) {
    .form-layout {
        min-width: 30em;
    }
}

@media (min-width: $tablet) {
    .left-bar-layout {
        min-width: 12em;
    }
}


    .container.is-fluid {
        margin: 0;
        padding: 0;
        max-width: none;

    }

.label-partner{
    padding: 0 !important;
}

.body-content {
    margin: 0;
    padding-left: 1em;
    padding-right: 1em;
}

.body-edit-permissions {
    min-height: 400px;
}

.MultiSelect .Header {
    background-color: white;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
}

.MultiSelect .Header--focusedActive {
    border-color: #1298d4;
}

.file-cta.is-danger {
    border-color: #ff3860;
}

.file-name.is-danger {
    border-color: #ff3860;
}

.file-label:hover .file-name.is-danger {
    border-color: #ff3860;
}

@media (min-width: 769px){
    .right-body-container.column.is-three-quarters, .right-body-container.column.is-three-quarters-tablet {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        width: auto;
    }
}

@media (min-width: 1024px){
    .right-body-container.column.column.is-three-quarters, .right-body-container.column.column.is-three-quarters-tablet {
        flex: none;
        width: 75%;
    }
}
