@import '../../css/variables.scss';

.navbar-button{
    min-width: 14rem;
    margin-right: 1.2em; // added 0.2 em because then IE tends to render scrollbar when it is not needed
    cursor: pointer;
}
.navbar-button-items{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: absolute;

    @media not screen and (max-width: $mobileBreakpoint) {
        min-width: 17.2em; // added 0.2 em because then IE tends to render scrollbar when it is not needed
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
        border-style: solid;
        border-top: none;
        width:inherit;
    }
    right: 0;
    @media screen and (max-width: $mobileBreakpoint) {
        left: 0;
        margin-top: 1em;
    }
    > div{
        color:inherit;
        background: inherit;
        @media not screen and (max-width: $mobileBreakpoint) {
            padding: 0.75em 0.25em 0.75em 0.25em;
            min-width: 14em;
            font-size: 0.875em;
        }
        @media screen and (max-width: $mobileBreakpoint) {
            padding: 0.75em 0;
            font-size: 1.5em;
        }
        border-bottom-width: 1px;
        border-bottom-style: solid;
        margin-left: 0.75em;
        margin-right: 0.75em;

        &.flags {
            margin-left: 0.25em;
        }
    }
}

.navbar-dropdown.is-right-on-smaller-desktop {
    @media not screen and (min-width: $extraBreakpoint) {
        left: auto;
        right: 0;
    }
}
